import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AlarmThresholdDocumentResponse, HTTPError } from "../../../types";
import { alarmThresholdByIdQueryKey } from "../query-cache";

export const useGetAlarmThresholdById = (alarmThresholdId: string) => {
  const queryKey = alarmThresholdByIdQueryKey(alarmThresholdId);

  const queryOptions: UseQueryOptions<AlarmThresholdDocumentResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AlarmThreshold.getById(alarmThresholdId),
    enabled: Boolean(alarmThresholdId)
  };

  const { isLoading, isSuccess, isError, data, error } =
    useQuery<AlarmThresholdDocumentResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    alarmThreshold: data?.data,
    error
  };
};
